/* Structure to name the classes in the CSS file:
BEM Methodology
[block]
[block]__[element]
[block]--[modifier]
[element]--[modifier]
[block]__[element]--[modifier] */

/* Display Grid */
.mainLayout__grid_LargeDevice {
  display: contents;
}

.mainLayout__grid_SmallDevice {
  display: none;
}

/* Login Page */
.login__box{
  background-color: #012626;
}

.login_pomegranate{
  width: 80%;
  margin: 2rem;
}

.login__brand_text{
  display: contents;
}

.loginSmallDevices__brand_text{
  display: none;
}

.login__brand p {
  margin-top: -10px;
  text-align: left;
  font-weight: 600;
  line-height: normal;
  width: 290px;
  color: var(--CTA, #04BF7B);
 }

.login_img{
  margin: 120px 0px 75px;
  width: 290px;
  height: auto;
}

.login__card {
  background-color: #ffffff;
  border-radius: 50px 0px 0px 50px;
  padding: 0rem 2rem 0rem 9rem;
  color: var(--Base-Text, #1E1E1E);
}

.login__form {
  margin-top: 200px;
}

.login__policies {
  margin-top: 20dvh;
  color: rgba(154, 157, 165, 1);
}

.login__social{
  margin-top: 3rem;
}

/* // Login Eye Icon styles */
.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  background: none;
  border-left: none;
}

/* Side Bar */
.sidebar__box {
  border-radius: 0px 50px 50px 0px;
  box-shadow: 0px 0px 20px rgba(48, 163, 221, 0.2);
  padding: 4rem 30px;
  height: 100vh;
/*
  max-width: 260px;
  min-width: 282px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
}

.sticky {
  position: fixed;
  top: 0;
  height: 100%;
}

.sidebar__logo_img {
  width: 148px;
  height: 30px;
  margin-bottom: 3rem;
  cursor: pointer;
}

/* Startup Profile */
.startupProfile__box{
  margin: 4rem;
}

.startupProfile__input input{
  width: 70rem;
  height: 51px;
  margin-top: 10px;
}

.startupProfile__circleBg {
  position: fixed;
  top: 70%;
  right: 0dvw;
  z-index: -1;
}

/* Build profile */
.buildProfile__box{
  padding: 4rem 4rem 2rem 4rem;
}

.buildProfile__companyDetails{
  font-weight: 700;
  color: #525252;
  font-size: 16px;
}


.buildProfile__card_title{
  color: #035928;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.buildProfile__card_description {
  color: #9A9DA5;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.buildProfile__modal_paragraph {
  color: #04BF7B;
  padding: 34.4px 55.76px;
  letter-spacing: 1px;
  font-size: 40px;
}

.buildProfile__modal_atepicker {
  width: 100%;
}

.buildProfile__founder_firstName {
  color: #343434;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 7px;
  text-align: center;
}

.buildProfile__founder_lastName {
  color: #343434;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.buildProfile__pdf_viewer .react-pdf__Page__canvas {
  box-shadow: 0px -3.792px 151.692px 0px rgba(4, 191, 123, 0.07), 0px -1.584px 63.373px 0px rgba(4, 191, 123, 0.05), 0px -0.847px 33.882px 0px rgba(4, 191, 123, 0.04), 0px -0.475px 18.994px 0px rgba(4, 191, 123, 0.04), 0px -0.252px 10.088px 0px rgba(4, 191, 123, 0.03), 0px -0.105px 4.198px 0px rgba(4, 191, 123, 0.02);
}

.buildProfile__pdfViewer .react-pdf__Page__canvas[style] {
  width: 100% !important;
  height: auto !important;
  border-radius: 15px;
  margin-bottom: 20px;
}

.buildProfile__pdfViewer_counter p {
  margin: 30px 0px;
  color: #7d7d7d;
}

.buildProfile__pdfViewer_button {
  background-color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 10px;
  margin-left: 10px;
}


/* ============= Sameer CSS files   =================*/


/* Profile Page CSS */
.profile__box {
  padding: 4rem 0rem 2rem 0rem;
}


.profile__titleForm {
  text-align: left;
  color: #343434;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.profile__titleForm p {
  margin-top: 3.6rem;
}

.profile__descriptionForm {
  text-align: left;
  color: #343434;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.profile__descriptionForm_links p {
  text-align: left;
  color: #343434;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  /* margin-top: 2.6rem; */
}

.profile__pictureUpload  {
 margin-top: 20px;
}


/* ===============toCHange====================== */

.profile__input_wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.profile__input_wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.profile__input_button {
  display: inline-block;
  background-color: #EEEEEE;
  color: white;
  padding-bottom: 5px ;
  border-radius: 5px;
  border: none;
  width: 80px;
  margin-top: 10px;
}

.profile__input_button:hover {
  background-color: #e1e1e1;
}

.profile__formsInteraction  {
  text-align: left;
  color: #343434;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


/* .profile__header {
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
   flex: space-between;
} */



/* .profile-avatar {
  width: 115px;
  height: 115px;
  font-size: 36px;
  background-color: #007aff;
  margin-bottom: 10px;
} */

.edit-icon {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}

.profile-details {
  width: 100%;
  margin-top: 20px;
}

.profile-details .MuiTypography-body1 {
  margin-bottom: 5px;
}

.edit-button {
  float: right;
}

.profile-button {
  margin-top: 20px;
}


/* Profile Page CSS END */
.circular__button {
  border: none;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;

}

.circular__button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #04BF7B;
  background: transparent;
}

/* Signup Page And CTA-Button */
.cta-button {
  width: 123.849px;
  height: 68.43px;
  border-radius: 15px;
  background: #04bf7b;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  margin-right: 100px;
  margin-bottom: 100px;
}

.cta-button-completed {
  width: 123.849px;
  height: 68.43px;
  border-radius: 15px;
  background: #04bf7b;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  margin-right: 100px;
  margin-bottom: 100px;
  box-shadow: 0px 9px 80px rgba(3, 89, 40, 0.12), 0px 3.76px 33.422px rgba(3, 89, 40, 0.15), 0px 2.01px 17.869px rgba(3, 89, 40, 0.18), 0px 1.127px 10.017px rgba(3, 89, 40, 0.18), 0px 0.599px 5.32px rgba(3, 89, 40, 0.16), 0px 0.249px 2.214px rgba(3, 89, 40, 0.10);
}

.is-invalid {
  border-color: red;
}
.customField.is-valid {
  border-color: green;
}


.PhoneInputInput {
  all: unset;
}

.PhoneInputCountryIcon {
  box-shadow: unset;
}

.PhoneInputCountrySelectArrow {
  font-size: 2em;
}


.signup__form {
  height: 70%;
  display: flex;
  flex-direction: column;

}

.signup__form__verification {
  display: flex;
  flex-direction: column;

}

.requiredField {
  position: absolute;
  color: rgb(255, 83, 83);
  font-size: 0.875em;
  margin-bottom: 1em;
  margin-top: 6px;
  transform: translateY(-100%);
  display: block;
}

.requiredField_onboarding {
  position: absolute;
  color: rgb(255, 83, 83);
  font-size: 0.875em;
  margin-bottom: 1em;
  transform: translateY(-100%);
  display: block;
}

.requiredField_signUp {
  position: absolute;
  color: rgb(255, 83, 83);
  font-size: 0.875em;
  margin-bottom: 1em;
  margin-top: -4px;
  transform: translateY(-100%);
  display: block;
}

.signup__social{
  margin-top: 1rem;
  margin-bottom: 5rem;
}

/* Signup Page END*/



/* Button for Let get to know you */
.signup__button_social {
  display: flex;
  justify-content:start;
  margin-top: 20px;
  border-radius: 10px; /* Adjust as needed */
}

.social__button {
  display: flex;
  align-items: center;
  background-color: #0077b5; /* LinkedIn Blue */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 10px; /* Adjust as needed */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.social__button:hover {
  background-color: #005582; /* Darker LinkedIn Blue for hover effect */
}

.social__button_logo {
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
  margin-right: 10px;
}

.social__button_text {
   /* display: inline-block;
  vertical-align: center; */
color: #FFF;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.overall__feedback_buttons p {
  margin-top: 12px;
  color: #7d7d7d;
  font-size: 16px;
  text-align: center;
}

/* CSS FOR FORGOT PASSWORD START*/
.swal2-confirm.btn-primary.swal2-styled{
  background-color: #04BF7B;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.swal2-cancel.btn-default.swal2-styled{
  background-color: #f00;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
/* CSS FOR FORGOT PASSWORD END */
/* Text Area */

@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 950px) {
  .mainLayout__grid_LargeDevice {
    display: none;
  }

  .mainLayout__grid_SmallDevice {
    display: contents;
  }

  .startupProfile__box{
    margin: 4rem 0rem 0rem 0rem;
  }
}

@media screen and (max-width: 899px) {
  .login__semiCircle {
    background-color: #012626;
    height: 290px;
    top: 0px;
    width: 190%;
    border-radius: 0% 0% 80% 80%;
    position: absolute;
  }

  .login_img{
    position: relative;
    top: 10px
  }

  .login__form {
    margin-top: 0;
  }

  .login__card {
    background-color: #ffffff;
    border-radius: 0px 0px 0px 0px;
    padding: 2rem;
    color: var(--Base-Text, #1E1E1E);
    padding-top: 44.68px;
    text-align: center;
  }

  .login__box{
    background: #ffffff;
  }

  .login_pomegranate{
    position: absolute;
    z-index: 0;
    width: 580px;
    top: 600px;
    opacity: 0.3;
  }

  .login__brand_text{
    display: none;
  }

  .loginSmallDevices__brand_text{
    display: contents;
  }

  .login__brand  {
    height: 304.7px;
   }

   .login__policies {
    margin-top: 9dvh;
    color: rgba(154, 157, 165, 1);
  }

  .profile__titleForm p {
    margin-top: 2rem;
  }

  .profile__descriptionForm p {
    margin-top: 0px;
  }

}

@media screen and (max-width: 768px) {


}

@media screen and (max-width: 425px) {


}

@media (max-width: 900px) {
  .login_pomegranate {
    display: none;
  }
}


.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;

  max-width: 800px;
  border-radius: 10px;
  outline: none;
  max-height: 90vh;
  overflow-y: auto;
  background-color: #fff;
}

@media (max-width: 992px) {
  .Modal {
    width: 70%;
  }
}

@media (max-width: 768px) {
  .Modal {
    width: 90%;
  }
}

@media (max-width: 576px) {
  .Modal {
    width: 95%;
  }
}

@media (max-width: 430px) {
  .Modal {
    width: 95%;

    padding: 0;
  }
}
