@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

/* Main Settings */

body {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


div.carousel-root  {
  padding: 0;
}

div.carousel .slide {
  text-align: left;
}

div.carousel-slider {
  height: 108%;
}

div.carousel-root .control-dots  {
  bottom: -12px;
}

div.carousel-root .control-dots .dot  {
  background: #09e769;
  width: 26px;
  height: 12px;
  border-radius: 50px;
  box-shadow: none;
}

/* Responsive Modal */
.Modal {
  position: relative;
  top: 50%;
  left: 50%;
  min-height: 70%;
  height: auto;
  width: 70%;
  overflow-y: auto;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: rgb(255, 255, 255);
  border-radius: 15px;
  box-shadow: 0px 11px 30px 12px rgba(0, 0, 0, 0.04);
  padding: 0px;
  border-radius: 15px;
  border: none
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

/* Colors */
.color__active {
  background-color: #04BF7B;
  color: white;
}

.color__inactive {
  background:  #bdfdcc;
  color: #04BF7B;
}

/* Buttons  */
.button__primary {
  width: 295px;
  height: 51px;
  font-size: 18px;
  text-transform: none;
  border-radius: 10px;
  border: none;
  font-weight: 500;
}

.button__link {
    margin: 30px 0px;
    color: #04BF7B;
    line-height: normal;
    padding: 0px;
    border: none;
    background: transparent;
    text-decoration: underline;
    font-weight: 400;
    font-size: 16px;
}

.button__secondary {
  border-radius: 10px;
  border: none;
  height: 4rem;
  width: 10rem;
  font-weight: 500;
}


.button__action {
  width: 144.648px;
  height: 37.15px;
  border-radius: 10px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0px;
}

.button__action_outline {
  width: 144.648px;
  height: 37.15px;
  border-radius: 10px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0px;
  border: #04BF7B 1px solid;
  background: transparent;
}


.button__edit {
  width: 34px;
  height: 34px;
  border-radius: 10% 13px 10% 30%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button__delete {
  width: 34px;
  height: 34px;
  border-radius: 13px 10% 30% 10%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}


.button__social {
  width: 295px;
  height: 51px;
  border-radius: 10px;
  border: 1px solid var(--Label-Field---Non-Active, #ACB8BC);
  color: var(--Input-field---Non-Active, #9A9DA5);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
  background-color: #ffffff;
}

.button__social img {
  margin-right: 6px;
}

.button__add_startup {
  width: 315px;
  height: 180px;
  color: aliceblue;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  border-radius: 20px;
  border: 1px solid var(--CTA, #04bf7b);
  background-color: var(--CTA, #04bf7b);
  box-shadow: 0px 38px 97px 0px rgba(0, 0, 0, 0.12),
    0px 15.875px 40.524px 0px rgba(0, 0, 0, 0.09),
    0px 8.488px 21.666px 0px rgba(0, 0, 0, 0.07),
    0px 4.758px 12.146px 0px rgba(0, 0, 0, 0.06),
    0px 2.527px 6.451px 0px rgba(0, 0, 0, 0.05),
    0px 1.052px 2.684px 0px rgba(0, 0, 0, 0.03);
}

.button__navigation {
  height: 3.9rem;
  width: 10rem;
  border-radius: 10px;
  font-size: 18px;
  text-transform: none;
  box-shadow: none;
  border: none;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button__status {
  width: 140.648px;
  border-radius: 40px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin-bottom: 14px;
}

.button__status svg {
  margin-right: 8px;
}

/* Fields */
.customField {
  max-width: 590px;
  height: 53px;
  border-radius: 10px;
  border: 1px solid var(--Label-Field---Non-Active, #ACB8BC);
}

.customField_user  {
  max-width: 630px;
  height: 53px;
  border-radius: 10px;
  border: 1px solid var(--Label-Field---Non-Active, #ACB8BC);
}

.fullWidth {
  max-width: 720px;
  width: 100%;
}

.pd-2 {
  padding: 12px;
}

.datepicker-style {
  border: 1px solid #dee2e6 !important;
  border-radius: 8px;
  padding: 0px 12px;
}

.datepicker-style:focus,
.datepicker-style input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.textEditorField {
  border-radius: 10px;
  border: 1px solid var(--Label-Field---Non-Active, #ACB8BC);
}

/* images */
.responsive{
  width: 100%;
  height: auto;
}

@media screen and (max-width: 900px) {
/* Fields */
.customField {
  max-width: none;
}

.customField_user  {
  max-width: none;
}

.Modal {
  height: 50%;
  width: 96%;
}


}
